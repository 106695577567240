<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)">
      <v-icon v-if="drawer"> mdi-chevron-left </v-icon>

      <v-icon v-else> mdi-chevron-right </v-icon>
    </v-btn>

    <v-toolbar-title class="hidden-sm-and-down">
      <v-icon style="color: #509dc8; margin-top: -5px">
        {{ getRouteIcon($route.name) }}
      </v-icon>
      {{ str[$route.name] ? str[$route.name] : $route.name }}
    </v-toolbar-title>

    <v-spacer />

    <div style="display: flex; align-items: center; margin-bottom: 5px">
      <span class="mr-2">Dark</span>
      <v-switch v-model="$vuetify.theme.dark" class="ma-0 pa-0" color="secondary" hide-details @change="onThemeChanged" />
    </div>

    <v-menu
      v-if="showChat"
      ref="menuChat"
      v-model="chatVisible"
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
      :content-class="
        'chat-menu ' +
        (chatIsLoading ? 'chat-menu-loading' : '') +
        (chatReady ? 'chat-menu-ready' : '') +
        (isMobile ? ' chat-menu-mobile' : '') +
        (isChatNewPage ? ' chat-new-page' : '') +
        (isTalkjs ? ' chat-menu-talkjs' : '')
      "
      :close-on-click="false"
      :close-on-content-click="false"
    >
      <template #activator="{ attrs, on }">
        <v-btn
          id="chat-events"
          class="ml-2"
          :color="chatNewMessages ? 'warning' : 'success'"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
          @click="onChangeChatStatus"
        >
          <v-icon
            v-if="!chatVisible || isChatNewPage"
            :style="{
              opacity: chatVisible && !isChatNewPage && !chatIsLoading ? '0.5' : '',
            }"
          >
            mdi-chat
          </v-icon>
          <v-icon v-if="chatVisible && !chatIsLoading && !menuChatDoubleClick && !isChatNewPage" class="vue-chat-icon-arrow">
            mdi-chevron-right
          </v-icon>
          <span v-if="chatVisible && !chatIsLoading && menuChatDoubleClick && !isChatNewPage" class="warning--text">
            {{ str['close'] }}
          </span>
          <v-progress-circular v-if="chatIsLoading" indeterminate color="secondary" class="chat-menu-loading-icon" />
          <span v-if="chatNewMessages && (!menuChatDoubleClick || isChatNewPage)">
            {{ chatNewMessages }}
          </span>
        </v-btn>
      </template>

      <v-list v-if="!isChatNewPage" :tile="false" nav :class="{ 'pt-0': isTalkjs }">
        <div v-if="chatUrl && !isTalkjs" class="chat-menu-content">
          <div v-if="isMobile && chatVisible && !chatIsLoading" class="row-align-right">
            <v-btn color="warning" text @click="closeChat">
              {{ str['close'] }}
            </v-btn>
          </div>
          <iframe
            id="chat-iframe"
            :src="chatUrl"
            :name="Date.now()"
            class="chat-menu-iframe"
            allow="geolocation; microphone; camera; notifications;"
          ></iframe>
        </div>
        <div v-if="isTalkjs">
          <Talkjs ref="talkjs" :refresh="refreshNewMessagesCounter" :open="openChat" :close="closeChat" :open-client-details="openClient" />
        </div>
      </v-list>
    </v-menu>

    <v-menu bottom left offset-y origin="top right" transition="scale-transition">
      <template #activator="{ attrs, on }">
        <v-btn v-if="notifsCount > 0" class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-badge color="red" overlap bordered>
            <template #badge>
              <span>{{ notifsCount }}</span>
            </template>
            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div>
          <app-bar-item v-for="(n, i) in notifications" :key="`item-${i}`">
            <v-list-item-title v-text="n" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>

    <v-menu bottom left offset-y origin="top right" transition="scale-transition">
      <template #activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div>
          <v-list-item @click="goPage('/home/userProfile')">
            <v-list-item-title>
              {{ str['update_user_profile'] }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="user && user.type === 0" @click="goPage('/home/ptPaymentsHistory')">
            <v-list-item-title>
              {{ str['payments'] }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="user && user.type === 0" @click="goPage('/home/settings')">
            <v-list-item-title>
              {{ str['settings'] }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="showSupport" @click="goPage('/home/support')">
            <v-list-item-title>
              {{ str['support'] }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="showRuffle" @click="goPage('/home/ruffle')">
            <v-list-item-title>
              {{ str['ruffle'] }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout()">
            <v-list-item-title>
              {{ str['logout'] }}
            </v-list-item-title>
          </v-list-item>
        </div>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import axios from 'axios'
import Api from '@/services/Api'
import Utils from '@/services/Utils'

// Components
import { VHover, VListItem } from 'vuetify/lib'

// Utilities
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'DashboardCoreAppBar',

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default,
              )
            },
          },
        })
      },
    },
    Talkjs: () => import('@/components/dashboard/talkjs/Talkjs'),
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const user = Utils.getUser()
    return {
      str: window.strings ? window.strings : {},
      isMobile: window.isMobile,
      showChat: user && user.configurations && user.configurations.chat && Utils.hasPermission('chat_view') ? true : false,
      isChatNewPage: user && user.configurations && user.configurations.chat && user.configurations.chat.new_page ? true : false,
      chatV2: user && user.configurations && user.configurations.chat && user.configurations.chat.version_2 ? true : false,
      isTalkjs: user && user.configurations && user.configurations.chat && user.configurations.chat.talkjs ? true : false,
      showSupport: Utils.hasPermission('support_view') ? true : false,
      showRuffle: user && user.configurations && user.configurations.ruffle && Utils.hasPermission('ruffle_view') ? true : false,
      notifsEnabled: false,
      notifsTimer: 0,
      notifsInterval: 30000, // 5min = 5 * 60s * 1000ms = 30000 ms
      notifsCount: 0,
      notifications: [],
      user: user,
      chatReady: false,
      chatUrl: null,
      chatVisible: true,
      chatIsLoading: true,
      chatNewMessages: 0,
      menuChatDoubleClick: false,
    }
  },
  computed: {
    ...mapState(['drawer']),
  },
  watch: {
    chatVisible: function (val) {
      if (val) {
        this.chatReady = true
        if (!this.isChatNewPage) {
          document.body.classList.add('body-chat-opened')
        }
      } else {
        if (!this.isChatNewPage) {
          document.body.classList.remove('body-chat-opened')
        }
      }
    },
  },
  beforeMount: function () {
    this.setChatUrl()
    this.startNotificationsTimer()
    this.activeChatEvents()
    this.activeChatNewPage()
  },
  beforeDestroy: function () {
    this.destroyChatPage()
  },
  methods: {
    ...mapMutations({
      setDrawer: 'SET_DRAWER',
    }),
    getRouteIcon(route) {
      let icon = ''
      switch (route) {
        case 'dashboard':
          icon = 'mdi-view-dashboard'
          break
        case 'clients':
          icon = 'mdi-account-group'
          break
        case 'training_plans_base':
          icon = 'mdi-content-paste'
          break
        case 'pdf_training_plans_base':
          icon = 'mdi-file-pdf-box'
          break
        case 'plans_base':
          icon = 'mdi-file-pdf-box'
          break
        case 'trains_base':
          icon = 'mdi-content-paste'
          break
        case 'exercises':
          icon = 'mdi-dumbbell'
          break
        case 'classes_exercises':
          icon = 'mdi-dumbbell'
          break
        case 'nutrition_base':
          icon = 'mdi-notebook-multiple'
          break
        case 'employees':
          icon = 'mdi-account-hard-hat'
          break
        case 'payments_packs':
          icon = 'mdi-account-credit-card'
          break
        case 'promotional_codes':
          icon = 'mdi-clipboard-list-outline'
          break
        case 'partnerships':
          icon = 'mdi-handshake'
          break
        case 'contents':
          icon = 'mdi-table-of-contents'
          break
        case 'supplements':
          icon = 'mdi-blender'
          break
        case 'streaming':
          icon = 'mdi-video'
          break
        case 'notifications':
          icon = 'mdi-bell-ring'
          break
        case 'lives':
          icon = 'mdi-video-account'
          break
        case 'chat':
          icon = 'mdi-chat'
          break
        case 'recipes':
          icon = 'mdi-nutrition'
          break
        case 'foods':
          icon = 'mdi-food-variant'
          break
        case 'nutrition_plans_base':
          icon = 'mdi-notebook-multiple'
          break
        case 'diets_base':
          icon = 'mdi-notebook-multiple'
          break
        case 'statistics':
          icon = 'mdi-finance'
          break
        case 'calendar':
          icon = 'mdi-calendar'
          break
        case 'bookings':
          icon = 'mdi-calendar'
          break
        case 'mkt_clint':
          icon = 'mdi-chart-box-outline'
          break
        case 'tutorials':
          icon = 'mdi-cast-education'
          break
        case 'scouting':
          icon = 'mdi-tab-search'
          break
        case 'tasks':
          icon = 'mdi-calendar-check'
          break
      }

      return icon
    },
    goPage(page) {
      if (this.$route.path !== page) {
        this.$router.navigate.push({ path: page })
      }
    },
    logout() {
      const self = this
      this.$isLoading(true)
      Api.logout(function () {
        if (self.showChat && !self.isTalkjs) {
          self.sendEventToChat({
            event: 'destroy',
          })
          setTimeout(function () {
            self.$isLoading(false)
            self.resetDashboard()
          }, 500)
        } else {
          self.$isLoading(false)
          self.resetDashboard()
        }
      })
    },
    resetDashboard() {
      this.stopNotificationsTimer()
      Utils.clearStorage()
      this.$router.navigate.replace({ path: '/' })
    },
    startNotificationsTimer() {
      if (this.notifsEnabled) {
        // Starts notifications timer
        this.notifsTimer = setInterval(this.onNotifsTimerElapsed, this.notifsInterval)
      }
    },
    stopNotificationsTimer() {
      if (this.notifsEnabled) {
        // Stops notifications timer
        clearInterval(this.notifsTimer)
      }
    },
    onNotifsTimerElapsed: function () {
      const self = this
      // Get notifications from server
      Api.getNotifications(axios.defaults.userDbId, function (response) {
        if (response.success) {
          for (let i = 0; i < response.data.length; i++) {
            const notification = response.data[i]
            self.notifications.push(notification.body)
            self.notifsCount = self.notifications.length
          }
        }
      })
    },
    setChatUrl: function () {
      if (this.showChat && !this.isTalkjs) {
        if (this.chatV2) {
          this.chatUrl = axios.defaults.chat2WebviewUrl + '?timestamp=' + new Date().getTime()
        } else {
          this.chatUrl =
            axios.defaults.chatWebviewUrl +
            'is_pt=true&theme=light&audio=true' +
            '&pt_id=' +
            axios.defaults.ptId +
            '&id=' +
            axios.defaults.ptId +
            '&log_id=' +
            axios.defaults.userId +
            '&log_email=' +
            axios.defaults.chatLogEmail +
            '&log_name=' +
            axios.defaults.chatLogName +
            (this.isChatNewPage ? '&new_page=true' : '') +
            (Utils.hasPermission('chat_delete') ? '&delete_room=true' : '') +
            '&dashboard_client_url=true&lng=' +
            window.language +
            '&timestamp=' +
            new Date().getTime()
        }
      }
    },
    onChangeChatStatus: function () {
      const self = this
      if (this.isTalkjs) {
        if (this.chatVisible) {
          this.closeChat()
        } else {
          this.openChat()
        }
        return true
      }
      if (this.isChatNewPage) {
        return this.openChat()
      }
      if (this.chatVisible) {
        if (this.menuChatDoubleClick) {
          this.menuChatDoubleClick = false
          this.closeChat()
          return true
        }
        this.menuChatDoubleClick = true
        setTimeout(function () {
          self.menuChatDoubleClick = false
        }, 3000)
      } else {
        this.openChat()
      }
    },
    closeChat: function () {
      this.chatVisible = false
      this.onChatMenuState()
    },
    openChat: function () {
      this.chatVisible = true
      this.openChatNewPage()
      this.onChatMenuState()
    },
    onChatMenuState: function () {
      if (this.isTalkjs) {
        this.$refs.talkjs.changeStatus(this.chatVisible)
      } else {
        if (this.chatVisible) {
          this.sendEventsToShowChat()
        } else {
          this.sendEventsToHideChat()
        }
      }
    },
    sendEventsToShowChat: function () {
      this.sendEventToChat({
        event: 'change-chat-visible',
        data: true,
      })
    },
    sendEventsToHideChat: function () {
      const self = this
      if (!this.isChatNewPage) {
        setTimeout(function () {
          self.sendEventToChat({
            event: 'change-chat-visible',
            data: false,
          })
          self.sendEventToChat({
            event: 'close-room',
          })
        }, 500)
      }
    },
    activeChatEvents: function () {
      const self = this

      if (this.showChat) {
        const getUser = (data, cb) => {
          if (data?.chat_id) {
            Api.getUserWithChatId(data.chat_id, (response) => {
              if (response.success && response.data[0]) {
                return cb(response.data[0])
              }
              getUserWithEmail(data, cb)
            })
          } else if (data?.chat_user_id) {
            Api.getUserWithChatUserId(data.chat_user_id, (response) => {
              if (response.success && response.data[0]) {
                return cb(response.data[0])
              }
              getUserWithEmail(data, cb)
            })
          } else {
            getUserWithEmail(data, cb)
          }
        }

        const getUserWithEmail = (data, cb) => {
          if (data?.email) {
            Api.getUserWithEmail(data.email, (response) => {
              if (response.success) {
                cb(response.data[0])
              } else {
                self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
              }
            })
          } else {
            cb(null)
          }
        }

        window.addEventListener('message', function (event) {
          try {
            if (event && event.data && event.data.indexOf('setImmediate') !== 0) {
              const data = JSON.parse(event.data)

              if (data?.event === 'chat-created') {
                self.sendEventToChat({
                  event: 'dashboard-initialize',
                  data: {
                    userId: self.user.chat_user_id,
                    strings: window.strings,
                    token: window.chatLoginToken,
                    mainToken: axios.defaults.authorization,
                  },
                })
              }

              if (data?.event === 'open-client-trigger' && data.data) {
                self.$isLoading(true)
                getUser(data.data, (user) => {
                  self.$isLoading(false)
                  if (user) {
                    self.openClient(user)
                  } else {
                    self.$alert(window.strings['client_not_found'], '', 'warning', Utils.getAlertOptions())
                  }
                })
              }

              if (data?.event === 'open-client-register' && data.data) {
                self.$isLoading(true)
                Api.getClientRegisterWithChatId(
                  {
                    chat_id: data.data,
                    fields: ['id', 'status'],
                  },
                  (response) => {
                    self.$isLoading(false)
                    if (response.success) {
                      if (response.data[0]) {
                        const registerStatusList = Utils.getRegisterStatus()
                        let openRegisterMessageError = null
                        if (registerStatusList?.length) {
                          const registerStatus = registerStatusList.find((st) => st.value === response.data[0].status)
                          if (registerStatus?.open_message_error) {
                            openRegisterMessageError = registerStatus.open_message_error
                          }
                        }
                        if (openRegisterMessageError) {
                          self.$alert(openRegisterMessageError, '', 'warning', Utils.getAlertOptions())
                        } else {
                          if (self.$route.path === '/home/dashboard') {
                            window.postMessage(
                              JSON.stringify({
                                event: 'dashboard-notification',
                                data: {
                                  id: data.event,
                                  data: { id: response.data[0].id },
                                },
                              }),
                              '*',
                            )
                          } else {
                            self.$router.navigate.push({
                              path: '/home/dashboard',
                              query: { register_id: response.data[0].id },
                            })
                          }
                        }
                      } else {
                        self.$alert(window.strings['client_not_found'], '', 'warning', Utils.getAlertOptions())
                      }
                    } else {
                      self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
                    }
                  },
                )
              }

              if (data?.event === 'new-messages') {
                if (self.chatIsLoading) {
                  self.chatVisible = false
                  self.chatIsLoading = false
                  setTimeout(() => {
                    self.sendEventsToHideChat()
                  }, 500)
                }
                self.chatNewMessages = data.data
              }

              if (data?.data && (data.event === 'open-chat-client' || data.event === 'open-new-chat-client')) {
                self.chatVisible = true
                if (self.isTalkjs) {
                  self.openClientTalkjs(data.data)
                } else {
                  self.sendEventToChat(data)
                  if (!self.isChatNewPage) {
                    setTimeout(() => {
                      document.documentElement.scrollTop = 0
                    }, 500)
                  }
                }
              }

              if (data?.data && data.event === 'link') {
                window.open(data.data, '_blank')
              }
            }
          } catch (error) {}
        })
      }
    },
    sendEventToChat: function (data) {
      if (this.showChat && !this.isTalkjs) {
        try {
          if (this.isChatNewPage) {
            if (window.chatPage) {
              window.chatPage.postMessage(JSON.stringify(data), '*')
              window.chatPage.focus()
              return true
            }
          } else {
            const iframe = document.getElementById('chat-iframe')
            if (iframe && iframe.contentWindow) {
              iframe.contentWindow.postMessage(JSON.stringify(data), '*')
              return true
            }
          }
          this.$alert(this.str['chat_open_room_failed'], '', 'warning', Utils.getAlertOptions())
        } catch (error) {}
      }
    },
    onThemeChanged: function () {
      localStorage.setItem('dashboard_theme', this.$vuetify.theme.dark ? 'dark' : 'light')
    },
    activeChatNewPage: function () {
      if (this.showChat && !this.isTalkjs && this.isChatNewPage) {
        const width = 550
        const height = window.screen.height
        const posX = window.screen.width - width + 40
        const posY = window.screen.height
        window.chatPage = window.open(this.chatUrl, '_blank', `width=${width},height=${height},left=${posX},top=${posY}`)
      }
    },
    openChatNewPage: function () {
      if (this.showChat && !this.isTalkjs && this.isChatNewPage) {
        if (window.chatPage && !window.chatPage.closed) {
          window.chatPage.focus()
        } else {
          this.activeChatNewPage()
        }
      }
    },
    destroyChatPage: function () {
      if (this.showChat && !this.isTalkjs && this.isChatNewPage && window.chatPage && !window.chatPage.closed) {
        window.chatPage.close()
      }
    },
    openClientTalkjs: function (data) {
      if (this.isTalkjs) {
        this.$refs.talkjs.openClient(data.id ? data.id : data)
      }
    },
    openClient: function (user) {
      const client = Utils.encodeClient(user)
      Utils.setStorage('client', client)
      window.showPaydayAlert = true
      window.clientPageCacheList = null
      if (this.$route.path === '/home/client') {
        this.$router.navigate.replace({
          path: '/home/empty',
          query: { tab: 0 },
        })
        setTimeout(() => {
          this.$router.navigate.replace({
            path: '/home/client',
            query: { tab: 'details' },
          })
        }, 500)
      } else {
        this.$router.navigate.push({ path: '/home/client' })
      }
    },
    refreshNewMessagesCounter: function (value) {
      this.chatNewMessages = value
      if (this.chatIsLoading) {
        setTimeout(() => {
          this.closeChat()
          this.chatIsLoading = false
        })
      }
    },
  },
}
</script>

<style>
.body-chat-opened .v-application--wrap {
  width: calc(100% - 550px) !important;
  overflow: auto;
  height: 100vh;
}

.body-chat-opened .v-dialog__content {
  width: calc(100% - 550px) !important;
}

.chat-menu {
  min-width: 550px !important;
  max-width: 550px !important;
  width: 100% !important;
  top: 0 !important;
  left: initial !important;
  right: 0 !important;
  position: absolute !important;
  z-index: -99 !important;
}

.chat-menu-mobile {
  min-width: 100% !important;
  max-width: 100% !important;
}

.chat-new-page {
  min-width: 0px !important;
  max-width: 0px !important;
  width: 0px !important;
}

.chat-menu-talkjs {
  min-width: 400px !important;
  max-width: 400px !important;
}

@media (max-width: 500px) {
  .chat-menu-talkjs {
    min-width: 100vw !important;
    max-width: 100vw !important;
  }
}

.chat-menu-iframe {
  width: 100%;
  height: calc(100vh - 15px);
  border: none;
}

.chat-menu-mobile .chat-menu-iframe {
  height: calc(100vh - 140px);
}

.chat-menu-ready {
  position: inherit !important;
  z-index: 9999 !important;
}

.chat-menu-loading {
  z-index: -99 !important;
  position: absolute !important;
}

.chat-menu-loading-icon {
  position: absolute;
  height: 25px !important;
  width: 25px !important;
  background: #ecececa6;
}

.chat-menu .v-list {
  overflow: hidden;
}

.chat-menu .chat-menu-content {
  width: 100%;
  background-color: #fff;
}
</style>
